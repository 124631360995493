import React from 'react';
import { DownloadAppImgContainer } from '../styled/DownloadApp.styled';
import ImgIcon from '../../../../components/ui/ImgIcon';
import { Box } from '@mui/material';

interface DownloadAppStoreButtonProps {
  height: string;
  width: string;
  buttonAlign?: string;
}

const storeLinks = [
  {
    href: 'https://play.google.com/store/apps/details?id=com.okao.inc.resactly',
    icon: '/new/images/icons/downloadGoogelPlay.svg',
    alt: 'download-app-from-google-store',
  },
  {
    href: 'https://apps.apple.com/in/app/resactly/id6498938210',
    icon: '/new/images/icons/downloadAppStore.svg',
    alt: 'download-app-from-apple-store',
  },
];

const DownloadAddStoreButton: React.FC<DownloadAppStoreButtonProps> = ({
  height,
  width,
  buttonAlign = 'start',
}) => {
  return (
    <DownloadAppImgContainer sx={{ justifyContent: buttonAlign }}>
      {storeLinks.map(({ href, icon, alt }) => (
        <Box
          component="a"
          target="_blank"
          href={href}
          rel="noreferrer"
          sx={{ zIndex: 150 }}
          key={href}
        >
          <ImgIcon
            icon={icon}
            alt={alt}
            height={height}
            width={width}
            wrapperStyle={{
              height,
              width,
            }}
          />
        </Box>
      ))}
    </DownloadAppImgContainer>
  );
};

export default DownloadAddStoreButton;
