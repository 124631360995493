import React from 'react'
import { Box, Typography } from '@mui/material'
import DownloadAddStoreButton from '../../pages/eater/home/components/DownloadAddStoreButton'

const WebMobilePromo: React.FC = () => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ px: 6 }}>
          <Typography
            variant="h2"
            fontWeight={800}
            sx={{
              mb: 4,
              fontSize: { xs: '26px !important', md: '36px !important' },
              textAlign: 'center',
              lineHeight: { xs: 1.1 },
              position: 'relative',
              zIndex: 110,
            }}
          >
            {'Download the Resactly app now!'}
          </Typography>
          <DownloadAddStoreButton
            height="40px"
            width="139px"
            buttonAlign="center"
          />
        </Box>
        <Box
          component="img"
          src="/new/images/promo-phone.png"
          alt="promo card shadow"
          sx={{
            width: { xs: 'calc(100vw - 40px)', sm: 'unset' },
            zIndex: 110,
          }}
        ></Box>
      </Box>
    </>
  )
}

export default WebMobilePromo
