import { AlertColor } from '@mui/material/Alert'
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'
import okaoAxios from '../helpers/axios'
import { convertToPredictedAndPublicRestaurantList } from '../helpers/converters'
import {
  ILandingCuisinesResponse,
  ILandingDetailsRequest,
  ILandingResponse,
  ILandingRestaurantList,
  IRestaurantsCityRequest,
  IRestaurantsCuisineRequest,
  LandingType,
  RestaurantAndPredictedRestaurant,
  RestaurantAndPredictedRestaurantResponse,
} from '../types'
import { RootState, store } from './'

export interface IRestaurantSlice {
  status: string
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined
  landingDetail: ILandingResponse | undefined
  localList: ILandingRestaurantList | undefined
  walletList: ILandingRestaurantList | undefined
  topRatedList: ILandingRestaurantList | undefined
  mustTryList: ILandingRestaurantList | undefined
  cuisineList: ILandingCuisinesResponse[] | []
  restaurants: RestaurantAndPredictedRestaurant[]
  pageSize: number
  page: number
  currentPage: number
  totalPages: number
  hasMore: boolean
}

const initialState: IRestaurantSlice = {
  status: 'idle',
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  landingDetail: undefined,
  localList: undefined,
  walletList: undefined,
  topRatedList: undefined,
  mustTryList: undefined,
  cuisineList: [],
  restaurants: [],
  pageSize: 12,
  page: 0,
  currentPage: 0,
  totalPages: 1,
  hasMore: false,
}

export const getLandingPageDetails = createAsyncThunk(
  'api/getLandingPageDetails',
  async (payload: ILandingDetailsRequest) => {
    const response = await okaoAxios.post(`/landing`, payload)
    return response?.data
  },
)
export const getRestaurants = createAsyncThunk(
  'api/getRestaurants',
  async (payload: ILandingDetailsRequest) => {
    const { page, type, ...data } = payload
    const response = await okaoAxios.post(
      `/landing/${type as string}/all/${
        page?.toLocaleString() as string
      }?pageSize=${store.getState().landing.pageSize}`,
      data,
    )
    return response?.data
  },
)

export const getLandingPageCuisineDetails = createAsyncThunk(
  'api/getLandingPageCuisineDetails',
  async (payload: IRestaurantsCuisineRequest) => {
    const { name, page, ...apiPayload } = payload
    const response = await okaoAxios.post(
      `/landing/${name}/cuisine/${page ?? 0}?pageSize=${
        store.getState().landing.pageSize ?? 25
      }`,
      apiPayload,
    )
    return response?.data
  },
)
export const getLandingPageCityRestaurantDetails = createAsyncThunk(
  'api/getLandingPageCityRestaurantDetails',
  async (payload: IRestaurantsCityRequest) => {
    const { name, page, ...apiPayload } = payload
    // eslint-disable-next-line
    const response = await okaoAxios.post(
      `/landing/${name}/city/${page ?? 0}?pageSize=${
        store.getState().landing.pageSize ?? 25
      }`,
      apiPayload,
    )
    return response?.data
  },
)

export const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },
    setRestaurants: (
      state,
      action: PayloadAction<{
        response: RestaurantAndPredictedRestaurantResponse
        isAuthenticated: boolean
      }>,
    ) => {
      if (!action.payload.isAuthenticated) {
        if (action.payload.response.page === 0) {
          state.restaurants = convertToPredictedAndPublicRestaurantList(
            action.payload.response.result,
          )
        } else {
          if (state.page < action.payload.response.page) {
            state.restaurants = [
              ...state.restaurants,
              ...convertToPredictedAndPublicRestaurantList(
                action.payload.response.result,
              ),
            ]
          }
        }
      } else {
        if (action.payload.response.page === 0) {
          state.restaurants =
            action.payload.response?.predictedRestaurants &&
            action.payload.response?.predictedRestaurants?.length
              ? action.payload.response?.predictedRestaurants
              : convertToPredictedAndPublicRestaurantList(
                  action.payload.response.result,
                )
        } else {
          if (state.page < action.payload.response.page) {
            state.restaurants = [
              ...state.restaurants,
              ...(action.payload.response?.predictedRestaurants &&
              action.payload.response?.predictedRestaurants?.length
                ? action.payload.response?.predictedRestaurants
                : convertToPredictedAndPublicRestaurantList(
                    action.payload.response.result,
                  )),
            ]
          }
        }
      }

      state.totalPages = Math.ceil(
        action.payload.response.totalCount / state.pageSize,
      )
      state.page = action.payload.response.page
      state.status = 'idle'
    },
    clearData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLandingPageDetails.fulfilled, (state, action) => {
        state.landingDetail = action.payload

        state.localList = action.payload?.lists.find(
          (item) => item.type === LandingType.LOCAL,
        )
        state.walletList = action.payload?.lists.find(
          (item) => item.type === LandingType.WALLET,
        )
        state.topRatedList = action.payload?.lists.find(
          (item) => item.type === LandingType.TOPRATED,
        )
        state.mustTryList = action.payload?.lists.find(
          (item) => item.type === LandingType.MUSTTRY,
        )

        state.cuisineList = action.payload?.cuisines || []

        state.status = 'idle'
      })
      .addMatcher(
        isAnyOf(
          getLandingPageDetails.rejected,
          getRestaurants.rejected,
          getLandingPageCuisineDetails.rejected,
          getLandingPageCityRestaurantDetails.rejected
        ),
        (state, action) => {
          state.shouldOpen = true
          state.message =
            'Oops! Looks like something went wrong on our end. But don’t worry, a quick refresh might just do the trick! Give it a whirl and let’s try this again. If the problem persists, we’ll be here to sort it out!'
          state.status = 'error'
          state.severity = 'error'
          state.landingDetail = undefined
        },
      )
      .addMatcher(
        isAnyOf(
          getLandingPageDetails.pending,
          getRestaurants.pending,
          getLandingPageCuisineDetails.pending,
          getLandingPageCityRestaurantDetails.pending
        ),
        (state, action) => {
          state.status = 'loading'
        },
      )
  },
})

export const selectIsLoading = (state: RootState): boolean =>
  state.landing.status === 'loading'

export const selectShouldOpen = (state: RootState): boolean =>
  state.landing.shouldOpen
export const selectMessage = (state: RootState): string | undefined =>
  state.landing.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state.landing.severity
export const selectLandingDetail = (
  state: RootState,
): ILandingResponse | undefined => state.landing.landingDetail

export const selectLocalList = (
  state: RootState,
): ILandingRestaurantList | undefined => state.landing.localList
export const selectWalletList = (
  state: RootState,
): ILandingRestaurantList | undefined => state.landing.walletList
export const selectTopRatedList = (
  state: RootState,
): ILandingRestaurantList | undefined => state.landing.topRatedList
export const selectMustTryList = (
  state: RootState,
): ILandingRestaurantList | undefined => state.landing.mustTryList
export const selectCuisineList = (
  state: RootState,
): ILandingCuisinesResponse[] | [] => state.landing.cuisineList
export const selectRestaurants = (
  state: RootState,
): RestaurantAndPredictedRestaurant[] | undefined => state.landing.restaurants

export const selectTotalPages = (state: RootState): number =>
  state.landing.totalPages
export const selectPage = (state: RootState): number => state.landing.page
export const selectPageSize = (state: RootState): number =>
  state.landing.pageSize

export const { setOpen, setRestaurants, clearData } = landingSlice.actions

export default landingSlice.reducer
