import {
  Card,
  CardContent,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import React from 'react'
import WebPromo from './promo/WebPromo'
import WebMobilePromo from './promo/WebMobilePromo'

interface PromoModalProps {
  onClose: () => void
  type: 'web' | 'mobile'
}

const PromoModal: React.FC<PromoModalProps> = ({ onClose, type }) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100,
      }}
    >
      <Card
        sx={{
          maxWidth: isSmallScreen ? 'calc(100% - 40px)' : 480,
          width: '100%',
          position: 'relative',
          borderRadius: '28px',
          background: theme.palette.primary[50],
          boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)',
          zIndex: 102,
        }}
      >
        <Box
          component="img"
          src="/new/images/promo-Vector-1.png"
          alt="promo card shadow"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            overflow: 'visible',
            width: '207px',
          }}
        ></Box>
        <Box
          component="img"
          src="/new/images/promo-Vector-2.png"
          alt="promo card shadow"
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            overflow: 'visible',
            width: '207px',
          }}
        ></Box>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: '#1C1B1F',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          <Close />
        </IconButton>

        <CardContent
          sx={{
            flex: 1,
            pb: '0 !important',
            px: type === 'web' ? { xs: 2, sm: 6 } : 0,
            pt: type === 'web' ? { xs: 7, sm: 10 } : 7,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {type === 'web' ? <WebPromo /> : <WebMobilePromo />}
        </CardContent>
      </Card>
    </Box>
  )
}

export default PromoModal
