import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import {
  IconUserPlus,
  IconCreditCardOff,
  IconArrowNarrowRight,
} from '@tabler/icons-react'
import CustomButton from '../ui/CustomButton'
import { useAuthHelper } from '../../hooks/useAuthHelper'

const FeatureBox: React.FC<{ icon: React.ReactNode; text: string }> = ({
  icon,
  text,
}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'flex-start',
        backgroundColor: theme.palette.primary[100],
        px: '24px',
        py: '20px',
        borderRadius: '16px',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          p: 1.5,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
        }}
      >
        {icon}
      </Box>
      <Typography
        variant="body1"
        fontWeight={500}
        sx={{ fontSize: '16px', lineHeight: 1.5 }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const WebPromo: React.FC = () => {
  const theme = useTheme()
  const { loginAuthUser } = useAuthHelper()

  return (
    <>
      <Typography
        variant="h2"
        fontWeight={800}
        sx={{
          mb: 4,
          fontSize: { xs: '26px !important', md: '36px !important' },
          textAlign: 'center',
          lineHeight: { xs: 1.1 },
          position: 'relative',
          px: { xs: 3, sm: 0 },
          zIndex: 110,
        }}
      >
        {"Don't Miss Out on the Good Stuff!"}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: {xs:2,sm:3} }}>
        <FeatureBox
          icon={<IconUserPlus stroke={2} size={22} />}
          text="Create a free account to access all of Resactly's features."
        />
        <FeatureBox
          icon={<IconCreditCardOff stroke={2} size={22} />}
          text="No credit card required, just unlimited possibilities."
        />
      </Box>

      <Box
        sx={{
          pt: 5,
          pb: {xs:4,sm:8},
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CustomButton
          title="Get Started Free"
          endIcon={<IconArrowNarrowRight stroke={2} />}
          sx={{
            backgroundColor: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
            color: 'white',
            border: 'none',
            px: 3,
            py: '12px',
            borderRadius: '10px',
          }}
          onClick={loginAuthUser}
        />
      </Box>
    </>
  )
}

export default WebPromo
