import { zodResolver } from '@hookform/resolvers/zod'
import {
  Autocomplete,
  Box,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { MuiTelInputInfo } from 'mui-tel-input'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { countries } from '../../../helpers/onboard-helper'
import { useAppDispatch, useAppSelector } from '../../../store'
import { selectLogin } from '../../../store/authSlice'
import {
  selectPersonalizePayload,
  setPersonalizePayload,
} from '../../../store/onboardSlice'
import {
  Eater,
  GeneralData,
  Login,
  OnboardPersonalizeMethods,
} from '../../../types'
import { errorLog } from '../../../utils/log-helper'
import OnboardTitleTypography from './OnboardTitleTypography'
import {
  OnboardPersonalizeDateOfBirth,
  OnboardRequestContainer,
  OnboardRequestGridContainer,
  OnboardRequestInnerGridContainer,
  OnboardRequestInputLabel,
  OnboardRequestMuiTelInput,
  OnboardRequestTextField,
} from './styled/OnboardRequest.styled'

const formSchema = z.object({
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  phone: z.string().min(1, { message: 'Phone number is required' }),
  dob: z.any(),
  addressLine1: z.string().min(1, { message: 'Address Line 1 is required' }),
  addressLine2: z.string().optional(),
  country: z.string().min(1, { message: 'Country is required' }),
  state: z.string().min(1, { message: 'State is required' }),
  city: z.string().min(1, { message: 'City is required' }),
  zipcode: z.string().min(1, { message: 'Zip Code is required' }),
  occupation: z.string().min(1, { message: 'Occupation is required' }),
  incomeLevel: z
    .number()
    .positive({ message: 'Monthly restaurant spend must be a positive number' }),
  gender: z.string().min(1, { message: 'Gender is required' }),
})

interface OnboardPersonalizeProps {
  key: number
  title: string
  subTitle: string
  setNextButtonDisable: (disable: boolean) => void
}

const OnboardPersonalize = React.forwardRef<
  OnboardPersonalizeMethods,
  OnboardPersonalizeProps
>(function OnboardPersonalize(
  { title, subTitle, setNextButtonDisable },
  ref,
): JSX.Element {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const loginResponse = useAppSelector(selectLogin)
  const personalizePayload = useAppSelector(selectPersonalizePayload)
  const [personalizeData, setPersonalizeData] =
    useState<GeneralData>(personalizePayload)

  useEffect(() => {
    const localStorageData = window.localStorage.getItem('login')
    const localLoginData: Login = localStorageData
      ? JSON.parse(localStorageData)
      : {}

    const onboardList: Eater | undefined =
      loginResponse?.eaterDTO ?? localLoginData.eaterDTO

    const personalize = {
      country: onboardList?.country,
      dob: dayjs(onboardList?.dob),
      firstName: onboardList?.firstName,
      lastName: onboardList?.lastName,
      phone: onboardList?.phone,
      addressLine1: onboardList?.addressLine1,
      addressLine2: onboardList?.addressLine2,
      city: onboardList?.city,
      state: onboardList?.state,
      zipcode: onboardList?.zipcode,
      incomeLevel: Number(onboardList?.incomeLevel),
      gender: onboardList?.gender,
      occupation: onboardList?.occupation,
    }

    setPersonalizeData(personalize)
  }, [loginResponse, personalizePayload])

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      dob: dayjs(personalizeData?.dob ?? '2019-01-25'),
      country: personalizeData.country,
      addressLine1: personalizeData.addressLine1,
      addressLine2: personalizeData.addressLine2,
      incomeLevel: personalizeData.incomeLevel,
      city: personalizeData.city,
      state: personalizeData.state,
      firstName: personalizeData.firstName,
      gender: personalizeData.gender,
      lastName: personalizeData.lastName,
      phone: personalizeData.phone,
      occupation: personalizeData.occupation,
      zipcode: personalizeData.zipcode,
    },
  })

  useEffect(() => {
    reset(personalizeData)
  }, [personalizeData, reset])

  useEffect(() => {
    setNextButtonDisable(!isValid)

    return () => {
      setNextButtonDisable(false)
    }
  }, [isValid, setNextButtonDisable])

  const getPayload = async (): Promise<GeneralData | undefined> => {
    try {
      let formData: z.infer<typeof formSchema> | undefined

      await handleSubmit(
        async (data: z.infer<typeof formSchema>): Promise<void> => {
          dispatch(setPersonalizePayload(data))
          formData = data
        },
      )()

      return formData
    } catch (error) {
      errorLog('Form submission error:', error)
    }
  }

  React.useImperativeHandle(ref, () => ({ getPayload }))

  return (
    <Box
      sx={{
        px: '20px',
        pb: {
          xs: '40px',
          md: '0px',
        },
      }}
    >
      <OnboardTitleTypography title={title ?? ''} subTitle={subTitle ?? ``} />
      <OnboardRequestContainer>
        <OnboardRequestGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="firstName">
                First Name
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="firstName"
                placeholder="Enter first name"
                {...register('firstName')}
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : ' '}
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="lastName">
                Last Name
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="lastName"
                placeholder="Enter last name"
                {...register('lastName')}
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName.message : ' '}
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="phone">
                Phone Number
              </OnboardRequestInputLabel>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <OnboardRequestMuiTelInput
                    {...field}
                    id="phone"
                    placeholder="Enter phone number"
                    error={!!errors.phone}
                    helperText={errors.phone ? errors.phone.message : ' '}
                    onChange={(value, info: MuiTelInputInfo) =>
                      field.onChange(value)
                    }
                  />
                )}
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="addressLine1">
                Address Line 1
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="addressLine1"
                placeholder="Enter address line 1"
                {...register('addressLine1')}
                error={!!errors.addressLine1}
                helperText={
                  errors.addressLine1 ? errors.addressLine1.message : ' '
                }
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="addressLine2">
                Address Line 2
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="addressLine2"
                placeholder="Enter address line 2"
                {...register('addressLine2')}
                error={!!errors.addressLine2}
                helperText={
                  errors.addressLine2 ? errors.addressLine2.message : ' '
                }
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="state">
                State
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="state"
                placeholder="Enter state"
                {...register('state')}
                error={!!errors.state}
                helperText={errors.state ? errors.state.message : ' '}
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="city">
                City
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="city"
                placeholder="Enter city"
                {...register('city')}
                error={!!errors.city}
                helperText={errors.city ? errors.city.message : ' '}
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="zipcode">
                Zip Code
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="zipcode"
                placeholder="Enter zip code"
                {...register('zipcode')}
                error={!!errors.zipcode}
                helperText={errors.zipcode ? errors.zipcode.message : ' '}
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="occupation">
                Occupation
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="occupation"
                placeholder="Enter occupation"
                {...register('occupation')}
                error={!!errors.occupation}
                helperText={errors.occupation ? errors.occupation.message : ' '}
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="incomeLevel">
              Monthly Restaurant Spend
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="incomeLevel"
                placeholder="Enter monthly restaurant spend"
                type="number"
                defaultValue={0}
                {...register('incomeLevel', { valueAsNumber: true })}
                error={!!errors.incomeLevel}
                helperText={
                  errors.incomeLevel ? errors.incomeLevel.message : ' '
                }
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <OnboardRequestInputLabel htmlFor="gender">
              Gender
            </OnboardRequestInputLabel>
            <Controller
              name="gender"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  sx={{
                    mb: '16px',
                    '&.MuiOutlinedInput-root': {
                      fontSize: '16px',
                      backgroundColor: theme.palette.background.paper,
                      '& fieldset': {
                        borderColor: theme.palette.secondary[300],
                        borderRadius: '8px',
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.secondary[300],
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.secondary[300],
                      },
                    },
                  }}
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="gender"
                  placeholder="Select gender"
                  {...field}
                  {...register('gender', { required: 'Gender is required' })}
                  error={!!errors.gender}
                >
                  <MenuItem value={'Male'}>
                    <Typography
                      noWrap
                      component="p"
                      fontWeight={500}
                      color={theme.palette.grey[800]}
                    >
                      Male
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'Female'}>
                    <Typography
                      noWrap
                      component="p"
                      variant="body2"
                      fontWeight={500}
                      color={theme.palette.grey[800]}
                    >
                      Female
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'Non-Binary'}>
                    <Typography
                      noWrap
                      component="p"
                      variant="body2"
                      fontWeight={500}
                      color={theme.palette.grey[800]}
                    >
                      Non-Binary
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'NA'}>
                    <Typography
                      noWrap
                      component="p"
                      variant="body2"
                      fontWeight={500}
                      color={theme.palette.grey[800]}
                    >
                      Prefer Not To Say
                    </Typography>
                  </MenuItem>
                </Select>
              )}
              {...(errors.gender && (
                <FormHelperText error>{errors.gender.message}</FormHelperText>
              ))}
            />
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="country">
                Country
              </OnboardRequestInputLabel>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="country"
                    options={countries.map((country) => country.label)}
                    autoHighlight
                    size="small"
                    PaperComponent={(props) => (
                      <Paper
                        {...props}
                        sx={{
                          fontSize: '14px !important',
                          fontWeight: `400 !important`,
                        }}
                      />
                    )}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: theme.palette.background.paper,
                        '& fieldset': {
                          borderColor: theme.palette.secondary[300],
                          borderRadius: '8px',
                        },
                        '&:hover fieldset': {
                          borderColor: theme.palette.secondary[300],
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: theme.palette.secondary[300],
                        },
                      },
                      mb: '16px',
                    }}
                    getOptionLabel={(option) => option}
                    value={field.value}
                    onChange={(e, newValue) => field.onChange(newValue)}
                    renderOption={(props, option) => {
                      const country = countries.find((c) => c.label === option)
                      return country ? (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={`${option}`}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            alt={country.code.toLowerCase()}
                            src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                          />

                          <Typography
                            noWrap
                            component="p"
                            variant="subtitle1"
                            sx={{
                              ml: 1,
                            }}
                            color={theme.palette.grey[800]}
                          >
                            {country.label} ({country.code}) + {country.phone}
                          </Typography>
                        </Box>
                      ) : null
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose a country"
                        error={!!errors.country}
                        helperText={
                          errors.country ? errors.country.message : ''
                        }
                      />
                    )}
                  />
                )}
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <OnboardPersonalizeDateOfBirth>
              <OnboardRequestInputLabel htmlFor="dob">
                Date of Birth
              </OnboardRequestInputLabel>

              <Box
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  width: '100%',
                }}
              >
                <Controller
                  name="dob"
                  control={control}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: 'small',
                            sx: {
                              '& .MuiOutlinedInput-root': {
                                backgroundColor: theme.palette.background.paper,
                                '& fieldset': {
                                  borderColor: theme.palette.secondary[300],
                                  borderRadius: '8px',
                                },
                                '&:hover fieldset': {
                                  borderColor: theme.palette.secondary[300],
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: theme.palette.secondary[300],
                                },
                              },
                            },
                          },
                        }}
                        {...field}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Box>
            </OnboardPersonalizeDateOfBirth>
          </OnboardRequestInnerGridContainer>
        </OnboardRequestGridContainer>
      </OnboardRequestContainer>
    </Box>
  )
})

export default OnboardPersonalize
