import { useAuth0 } from '@auth0/auth0-react'
import { debounce, ThemeProvider, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { getLocation } from '../helpers/location-helper'
import { ONBOARD_PATH_PARAMS, OnboardPage } from '../helpers/onboard-helper'
import { ROUTES } from '../helpers/routes-helper'
import { useAppDispatch, useAppSelector } from '../store'
import {
  getUserInfo,
  loginAudit,
  selectLogin,
  setAccessToken,
  setLocation,
} from '../store/authSlice'
import theme from '../theme'
import { errorLog } from '../utils/log-helper'
import { checkAndClearAuth } from '../helpers/auth-helper'
import CenteredLogoWithLoader from '../components/ui/CenteredLogoWithLoader'
import PromoModal from '../components/PromoModal'
import { useDevice } from '../hooks/useDevice'

interface Props {
  children?: any
}

const BaseLayout: React.FC<Props> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isHybrid, deviceInfo } = useDevice()

  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  const loginResponse = useAppSelector(selectLogin)
  const [dataFetched, setDataFetched] = useState(false)
  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false)
  const [isPromoModalMobile, setIsPromoModalMobile] = useState(false)

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()

  const getOnboardRoute = (): string => {
    const stepToPathMap: { [key in OnboardPage]?: string } = {
      [OnboardPage.ALLERGIES]: ONBOARD_PATH_PARAMS.ALLERGIES,
      [OnboardPage.FAVORITES]: ONBOARD_PATH_PARAMS.PERSONAL_FAVORITES,
      [OnboardPage.PREPARATIONS]: ONBOARD_PATH_PARAMS.FOOD_PREPARATIONS,
      [OnboardPage.DINING_FREQUENCY]: ONBOARD_PATH_PARAMS.DINING_FREQUENCY,
      [OnboardPage.INGREDIENTS]: ONBOARD_PATH_PARAMS.INGREDIENTS,
      [OnboardPage.CUISINES]: ONBOARD_PATH_PARAMS.CUISINES,
      [OnboardPage.AMBIENCE]: ONBOARD_PATH_PARAMS.DINING_AMBIENCE,
      [OnboardPage.DIETARY_PREFERENCES]:
        ONBOARD_PATH_PARAMS.DIETARY_PREFERENCE_RESTRICTION,
      [OnboardPage.SOCIAL_MEDIA]: ONBOARD_PATH_PARAMS.SOCIAL,
      [OnboardPage.PERSONALIZE]: ONBOARD_PATH_PARAMS.PERSONALIZE,
      [OnboardPage.LOCATE_YOUR_RESTAURANT]:
        ONBOARD_PATH_PARAMS.LOCATE_YOUR_RESTAURANT,
      [OnboardPage.REQUEST_ONBOARDING_RESTAURANT]:
        ONBOARD_PATH_PARAMS.REQUEST_ONBOARDING_RESTAURANT,
    }

    const step = loginResponse?.eaterDTO?.step
    return stepToPathMap[step ?? ''] || ONBOARD_PATH_PARAMS.CHOOSE_ROLE
  }

  useEffect(() => {
    if (loginResponse) {
      void handleNavigation()
    }
  }, [loginResponse])

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      void fetchData()
    }

    if (!isAuthenticated && !isLoading) {
      checkAndClearAuth()
      setDataFetched(true)
    }
  }, [isLoading, isAuthenticated])

  const handleNavigation = async (): Promise<void> => {
    const status = loginResponse?.status
    const eaterDTO = loginResponse?.eaterDTO
    const restaurantDTO = loginResponse?.restaurantDTO
    const chefDTO = loginResponse?.chefDTO

    const location = await getLocation()
    dispatch(setLocation(location))

    switch (status) {
      case 'ACTIVE':
        if (eaterDTO?.step === 'DONE') {
          if (eaterDTO) {
            navigate(
              `${window.location.pathname}${window.location.search}${window.location.hash}`,
            )
          } else {
            navigate(ROUTES.HOME)
          }
        } else if (restaurantDTO) {
          navigate(ROUTES.RESTAURANT_ADMIN_PENDING)
        } else if (chefDTO) {
          navigate(ROUTES.CHEF_DASHBOARD)
        } else {
          navigate(`${ROUTES.ONBOARD}/${getOnboardRoute()}`)
        }
        break
      case 'TRIAL':
      case 'SUBSCRIBED':
        navigate(`${ROUTES.ONBOARD}/${getOnboardRoute()}`)
        break
      case 'NEW':
        if (!eaterDTO) {
          navigate(`${ROUTES.ONBOARD}/${ONBOARD_PATH_PARAMS.CHOOSE_ROLE}`)
        }
        break
      case 'PENDING':
        navigate(ROUTES.RESTAURANT_ADMIN_PENDING)
        break
      case 'INACTIVE':
        navigate(ROUTES.UNAUTHORIZED)
        break
      case 'DELETED':
        navigate(ROUTES.RECOVER_ACCOUNT)
        break
      case 'BLOCKED':
        navigate(ROUTES.RECOVER_BLOCKED_ACCOUNT)
        break
      default:
        break
    }
  }

  const fetchData = async (): Promise<void> => {
    try {
      const token = await getAccessTokenSilently()
      localStorage.setItem('isUserLoggedIn', 'true')
      dispatch(setAccessToken(token))
      await dispatch(getUserInfo())
      await dispatch(loginAudit())
      setDataFetched(true)
    } catch (error) {
      errorLog('Error fetching data:', error)
    }
  }

  useEffect(() => {
    const shouldShowModal = !isHybrid && deviceInfo?.platform === 'web'
    const isUserLoggedIn = localStorage.getItem('isUserLoggedIn')
    const handleSetModalOpen = (): void => {
      if (isMediumScreen && !isHybrid) {
        setIsPromoModalMobile(true)
      }
      setIsPromoModalOpen(true)
    }

    const debouncedSetModalOpen = debounce(handleSetModalOpen, 5000)

    if (!isAuthenticated && shouldShowModal && !isUserLoggedIn) {
      debouncedSetModalOpen()
    }

    if (shouldShowModal && isMediumScreen) {
      debouncedSetModalOpen()
    }

    if (isHybrid && isMediumScreen && !isAuthenticated) {
      debouncedSetModalOpen()
    }

    return () => {
      debouncedSetModalOpen.clear()
    }
  }, [isAuthenticated, isHybrid, deviceInfo, isMediumScreen])

  const onPromoClose = (): void => {
    const isUserLoggedIn = localStorage.getItem('isUserLoggedIn')
    if (isPromoModalMobile && (isUserLoggedIn ?? isAuthenticated)) {
      setIsPromoModalOpen(false)
      setIsPromoModalMobile(false)
    } else if (isPromoModalMobile && !isAuthenticated) {
      setIsPromoModalMobile(false)
    } else {
      setIsPromoModalOpen(false)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {(isLoading || !dataFetched) && <CenteredLogoWithLoader />}
      {dataFetched && <Outlet />}
      {isPromoModalOpen && (
        <PromoModal
          onClose={onPromoClose}
          type={isPromoModalMobile ? 'mobile' : 'web'}
        />
      )}
    </ThemeProvider>
  )
}

export default BaseLayout
